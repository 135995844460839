export const API_KEY = {
    "fyndx1": {
        "cluster_url": "https://api.fyndx1.de",
        "api_key": "64ba60a38d8cbcb20d67d8ea",
        "api_secret": "zNN1rJbLLgpQzsU"
    },
    "fyndx5": {
        "cluster_url": "https://api.fyndx5.de",
        "api_key": "64044b3f9d15689c6b60f8b2",
        "api_secret": "CVKXMW1CJymMLhX"
    },
    "fynd": {
        "cluster_url": "https://api.fynd.com",
        "api_key": "63f7787f4adb0bbd79de0183",
        "api_secret": "2XqX66u5tcQviWf"
    }
}
export const MAX_INTERVAL = 7
