import { Extension } from '@gofynd/fdk-extension-bridge-javascript'

import { API_KEY } from './helper/constant.js';
import root from "window-or-global";
const ENVS = ['fyndx1', 'fyndx5' ,'fynd']

let env = root.location.hostname.split('.')[2];

if (!ENVS.includes(env)) {
    env = ENVS[0]
}

let ext = new Extension({
    apiKey: root.env && root.env?.ext?.api_key || API_KEY[env].api_key
});

export default ext
